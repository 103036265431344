class SizeGuide extends HTMLElement {
  constructor() {
    super();
    this.charts = this.querySelectorAll('.size-guides');
    this.inches = this.querySelector('.inches');
    this.cm = this.querySelector('.cm');

    console.log(this.images);
    this.cm.addEventListener('click', this.toggle.bind(this));
    this.inches.addEventListener('click', this.toggle.bind(this));
  }

  toggle() {
    this.inches.classList.toggle('underline');
    this.cm.classList.toggle('underline');
    this.charts.forEach((chart) => {
      chart.parentElement.classList.toggle('block');
      chart.parentElement.classList.toggle('hidden');
    });
  }
}

customElements.define('size-guide', SizeGuide);
